import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { createRef, FunctionComponent, useState } from 'react';
import { useToken } from '../../hooks/useToken';
import CompaniesDropdown from '../../components/companies-dropdown/CompaniesDropdown';

import DataGrid, { Column, Item, RangeRule, RequiredRule, Toolbar, ValidationRule } from 'devextreme-react/data-grid';

import CustomStore from 'devextreme/data/custom_store';
import './Employees.css'
import notify from 'devextreme/ui/notify';
import { AddEmployee, GetCompanyEmployees, GetCompanyPositionsAsync, UpdateEmployee, UploadOptishiftData } from '../../services/http-service';
import { Company, Position } from '../../models/company';
import { Employee } from '../../models/employee';
import { Button, LoadIndicator } from 'devextreme-react';
import dxDataGrid from 'devextreme/ui/data_grid';

const Employees: FunctionComponent = () => {
  let token = useToken();

  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(undefined);
  const [unsentEmployees, setUnsentEmployees] = useState(false);
  const [allowUploadToOptishift, setAllowUploadToOptishift] = useState(true);
  const employeeGrid = createRef<DataGrid>();

  const getCompanyPositionsStore = (selectedCompany: Company | undefined) => new CustomStore({
    loadMode: "raw",
    key: "id",
    load: (o) => {
      if (!token.getToken()) {
        return Promise.reject();
      }

      if (!selectedCompany) return Promise.resolve([]);

      return GetCompanyPositionsAsync(token.getToken()!, selectedCompany?.id)
        .then(d => d as (Position | null)[])
        .then(d => { d.unshift(null); return d; })
        .catch(err => {
          console.error(err);
          notify("There was an error while retrieving the positions", "error", 2500)
          return Promise.reject();
        })
    }
  });

  // For client pagination
  const getDataStore = (selectedCompany: Company | undefined) => new CustomStore({
    loadMode: "processed",
    key: "id",
    load: async (o) => {
      if (!token.getToken()) {
        return [];
      }

      if (!selectedCompany) return [];
      try {
        let employees = await GetCompanyEmployees(selectedCompany.id, token.getToken()!);
        setUnsentEmployees(employees.filter(e => e.positionId && !e.sent).length > 0);
        return employees;
      }
      catch (err) {
        console.error(err);
        notify("There was an error while fetching the employee data", "error", 2500)
        return [];
      }
    },
    insert: async (value) => {
      if (!token.getToken()) {
        return;
      }

      if (!selectedCompany) return Promise.resolve([]);

      let employee = value as Employee;
      employee.companyId = selectedCompany.id;

      const res = await AddEmployee(employee, token.getToken()!);
      if (!res.ok) {
        const err = await res.json();
        notify("There was an error while adding the new employee: " + err.message, "error", 2500)
      } else {
        notify("The new employee has been added.", "success", 2500)
      }
    },
    update: async (key, values) => {
      if (!token.getToken() || !selectedCompany) {
        return;
      }

      let employee = values as Employee;
      employee.companyId = selectedCompany.id;

      const res = await UpdateEmployee(employee, token.getToken()!);

      if (!res.ok) {
        const err = await res.json();
        notify("There was an error while updating the employee: " + err.message, "error", 2500)
      }
    }
  });

  let [dataGrid, setDataGrid] = useState<dxDataGrid>();

  return (
    <div className="camp-table">
      <AuthenticatedTemplate >
        <CompaniesDropdown onSelectedCompanyChanged={(c) => setSelectedCompany(c)} />
        {unsentEmployees &&
          <h4 style={{ color: 'red' }}>There are changes that haven't been sent to Optishift. Click 'Upload Changes to Optishift' to upload the changes.</h4>
        }
        <DataGrid
          ref={employeeGrid}
          id='gridContainer'
          showBorders={true}
          dataSource={token && selectedCompany ? getDataStore(selectedCompany) : undefined}
          pager={{ allowedPageSizes: [10, 20, 50], visible: true, displayMode: "adaptive", showPageSizeSelector: true }}
          editing={{ allowUpdating: true, mode: "form", allowAdding: true, useIcons: true }}
          filterRow={{ visible: true }}
          repaintChangesOnly={true}
          onRowUpdating={(opt) => {
            opt.newData = { ...opt.oldData, ...opt.newData };
          }}
          onInitialized={(e) => {
            setDataGrid(e.component);
          }}
          onEditorPreparing={(e) => {
            if (e.dataField !== 'employeeId') return;

            if (e.row?.isNewRow && e.row.rowType === 'detail')
              e.editorOptions.readOnly = false;
            else
              e.editorOptions.readOnly = true;
          }}
        >
          <Toolbar>
            <Item location="before">
              <Button icon='add'
                text="Add Employee" onClick={(_: any) => {
                  if (!selectedCompany) {
                    notify("Please select a company first.", "error", 2500)
                  } else {
                    dataGrid?.addRow();
                  }
                }} />
            </Item>
            <Item location="before">
              <Button icon='refresh'
                disabled={!allowUploadToOptishift}
                text="Upload Changes to Optishift" onClick={(_: any) => {
                  if (!selectedCompany) {
                    notify("Please select a company first.", "error", 2500)
                  } else {
                    setAllowUploadToOptishift(false);
                    UploadOptishiftData(selectedCompany.id, token.getToken()!).then((_) => {
                      employeeGrid.current?.forceUpdate();
                      setAllowUploadToOptishift(true);
                    });
                  }
                }} />
            </Item>
            <Item location="before">
              <LoadIndicator visible={!allowUploadToOptishift} />
            </Item>
          </Toolbar>
          <Column dataField="employeeId" dataType="string">
            <ValidationRule>
              <RequiredRule></RequiredRule>
            </ValidationRule>
          </Column>
          <Column dataField="lastName">
            <ValidationRule>
              <RequiredRule></RequiredRule>
            </ValidationRule>
          </Column>
          <Column dataField="firstName" />
          <Column dataField="gender" dataType="number">
            <ValidationRule>
              <RequiredRule></RequiredRule>
              <RangeRule min={0} max={1} message="Please select between 0 (Female) and 1 (Male)"></RangeRule>
            </ValidationRule>
          </Column>
          <Column dataField="workDays" caption="Work Days (week)" dataType="number"  >
            <ValidationRule>
              <RequiredRule></RequiredRule>
            </ValidationRule>
          </Column>
          <Column dataField="shiftDuration" caption="Shift Duration (hours)" dataType="number"  >
            <ValidationRule>
              <RequiredRule></RequiredRule>
            </ValidationRule>
          </Column>
          <Column dataField="leaveRight" dataType="number"  >
            <ValidationRule>
              <RequiredRule></RequiredRule>
            </ValidationRule>
          </Column>
          <Column dataField="leaveRemaining" dataType="number"  >
            <ValidationRule>
              <RequiredRule></RequiredRule>
            </ValidationRule>
          </Column>
          <Column dataField="hireDate" dataType="date" format={"dd/MM/yyyy"}  >
            <ValidationRule>
              <RequiredRule></RequiredRule>
            </ValidationRule>
          </Column>
          <Column dataField="retireDate" dataType="date" format={"dd/MM/yyyy"} />
          <Column dataField="vatId" dataType="string" />
          <Column dataField="socialSecurityNumber" dataType="string" />
          <Column dataField="labourInspectionSpecialityId" caption="Labour Inspection Speciality Id (Greece only)" dataType="string" />
          <Column dataField="status" dataType="boolean" />
          <Column dataField="positionId" dataType="number" lookup={{
            dataSource: getCompanyPositionsStore(selectedCompany),
            displayExpr: (p: Position) => `${p ? (p?.positionName ?? "Unnamed") + ' (' + (p?.positionId) + ')' : "None"}`,
            valueExpr: (p: Position) => p?.positionId || null
          }} />
          <Column dataField="username" caption="Username" />
          <Column dataField="sent" caption="Synced" dataType="boolean" allowEditing={false} />
        </DataGrid>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        Please sign-in to see this page.
      </UnauthenticatedTemplate>
    </div>
  );
}

export default Employees;
